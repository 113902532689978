// Firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAUAK17pUMqqYXhoRGj-y5II0jgR4odVrY",
    authDomain: "barsmaker-8769d.firebaseapp.com",
    databaseURL: "https://barsmaker-8769d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "barsmaker-8769d",
    storageBucket: "barsmaker-8769d.appspot.com",
    messagingSenderId: "155631441271",
    appId: "1:155631441271:web:810d601810244886c9fd72",
    measurementId: "G-SJV889NPSY"
};

const app = initializeApp(firebaseConfig);

// Inizializziamo i vari servizi
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);