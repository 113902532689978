// PostForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import Realtime Database
import { ref as dbRef, getDatabase, push } from 'firebase/database';

// Import Auth
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import {
  getStorage, ref as storageRef, uploadBytes,
  getDownloadURL
} from 'firebase/storage';


import {
  Button,
  TextField,
  Typography,
  Box,
  Tooltip,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import './PostForm.css';
const auth = getAuth();
// Mappa colori emozionali (solo esempio)
const emotionalColors = {
  Anger: { color: '#FF0000', description: 'Red = Anger' },
  Security: { color: '#FFD700', description: 'Yellow = Security' },
  Sadness: { color: '#0000FF', description: 'Blue = Sadness' },
  Questioning: { color: '#00FF00', description: 'Green = Questioning' },
  Hate: { color: '#8B0000', description: 'Bordeaux = Hate' },
  Calm: { color: '#00CED1', description: 'Turquoise = Calm' },
  Double: { color: '#800080', description: 'Purple = Double' },
};

const splitTextIntoBars = (text, maxLength = 40) => {
  const words = text.split(' ');
  const bars = [];
  let currentBar = '';

  words.forEach((word) => {
    if ((currentBar + word).length > maxLength) {
      bars.push(currentBar.trim());
      currentBar = word + ' ';
    } else {
      currentBar += word + ' ';
    }
  });

  if (currentBar.trim() !== '') {
    bars.push(currentBar.trim());
  }
  return bars;
};

function PostForm() {
  const database = getDatabase();
  // Recupera utente

  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();

  // Stati
  const [bars, setBars] = useState([]);
  const [newBar, setNewBar] = useState('');
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [filePreview, setFilePreview] = useState(null);
  const [newBarColor, setNewBarColor] = useState('#ffffff');
  const [isUploading, setIsUploading] = useState(false);
  const storage = getStorage();
  console.log("Utente attuale:", user); // Se null => non loggato

  // Funzione per resettare il form
  const clearForm = () => {
    setBars([]);
    setNewBar('');
    setFile(null);
    setFileType('');
    setFilePreview(null);
  };

  // Funzione per postare su Realtime Database
  const handlePost = async () => {
    // // Se non ci sono barre, chiediamo di inserirle
    // if (bars.length === 0) {
    //   setError('Please add at least one bar before posting.');
    //   return;
    // }

    // Se l'utente non è loggato, rimandalo al login
    if (!user) {
      navigate('/login');
      return;
    }

    setIsUploading(true);
    let fileURL = '';

    try {
      // // Se c'è un file, caricalo su Firebase Storage
      // if (file) {
      //   // Crea un riferimento univoco (nomeFile_data)
      //   const fileRef = storageRef(
      //     storage,
      //     `uploads/${user.uid}/${file.name}_${Date.now()}`
      //   );
      //   const snapshot = await uploadBytes(fileRef, file);
      //   fileURL = await getDownloadURL(snapshot.ref);
      // }

      // // Salva il post nella Realtime Database
      console.log(database);
      const postRef = dbRef(database, `users/${user.uid}/posts`);
      await push(postRef, {
        createdAt: new Date().toISOString(),
        bars: bars, // Salva come array di oggetti (testo + colore)
        user: {
          displayName: user.displayName || 'Anonymous',
          uid: user.uid,
          photoURL: user.photoURL || '',
        },
        fileURL,
        fileType,
        reactions: [],
        comments: [],
      });

      // Resetta il form dopo la pubblicazione
      clearForm();
    } catch (err) {
      console.error('Error posting bars: ', err);
    } finally {
      setIsUploading(false);
    }
  };

  // Gestisce il cambio di testo
  const handleInputChange = (e) => {
    setNewBar(e.target.value);

  };

  // Funzione che aggiunge la barra scritta nell'input all'array bars
  const handleAddBar = () => {
    if (newBar.trim() === '') return; // opzionale: evita barre vuote

    // Aggiunge la barra nell'array e resetta l'input
    setBars((prevBars) => [...prevBars, newBar]);
    console.log('test bar new' , newBar);
    setNewBar('');
  };

  // Quando premiamo Enter, creiamo le barre
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && newBar.trim() !== '') {
      const splitBars = splitTextIntoBars(newBar.trim()).map((text) => ({
        text,
        color: newBarColor,
      }));
      setBars([...bars, ...splitBars]);
      setNewBar('');
    }
  };

  // Quando selezioniamo un file da caricare
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Controllo dimensione max 10MB
      if (selectedFile.size > 10 * 1024 * 1024) {
        return;
      }
      setFile(selectedFile);
      setFileType(selectedFile.type);

      // Preview: se è immagine uso FileReader, se audio/video uso URL.createObjectURL
      if (selectedFile.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => setFilePreview(reader.result);
        reader.readAsDataURL(selectedFile);
      } else if (
        selectedFile.type.startsWith('video/') ||
        selectedFile.type.startsWith('audio/')
      ) {
        setFilePreview(URL.createObjectURL(selectedFile));
      }
    }
  };




  // Se l'utente non è loggato, mostriamo un messaggio
  if (!user) {
    return (
      <Box className="login-prompt">
        <Typography variant="body1">
          Please <a href="/login">log in</a> to post your bars.
        </Typography>
      </Box>
    );
  }
  console.log('ciao')
  return (
    <Box className="post-form-container">
      <Typography variant="h5" gutterBottom>
        Create a New Post
      </Typography>

      {/* Input per le barre */}
      <TextField
        value={newBar}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Write your bars here..."
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        error={!!error}
        helperText={error}
      />
      <Button onClick={handleAddBar}>
        Prepara Barra</Button>
      {/* Anteprima delle barre */}
      <Box className="bars-preview">
        {bars.map((bar, index) => (
          <Box
            key={index}
            className="preview-bar"
            style={{ backgroundColor: bar.color }}
          >
            {bar.text}
          </Box>
        ))}
      </Box>

      {/* Selettore di colori emozionali */}
      <Box className="emotional-colors">
        {Object.keys(emotionalColors).map((emotion) => (
          <Tooltip key={emotion} title={emotionalColors[emotion].description}>
            <Box
              className="color-option"
              style={{ backgroundColor: emotionalColors[emotion].color }}
              onClick={() => setNewBarColor(emotionalColors[emotion].color)}
            />
          </Tooltip>
        ))}
      </Box>

      {/* Upload del file */}
      <input
        type="file"
        accept="audio/*,image/*,video/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-upload"
      />
      <label htmlFor="file-upload">
        <Button
          variant="outlined"
          component="span"
          startIcon={<UploadIcon />}
        >
          Upload File
        </Button>
      </label>

      {/* Anteprima del file se esiste */}
      {filePreview && (
        <Box className="file-preview">
          {fileType.startsWith('image/') && (
            <img src={filePreview} alt="Uploaded" className="preview-image" />
          )}
          {fileType.startsWith('video/') && (
            <video controls className="preview-video">
              <source src={filePreview} type={fileType} />
              Your browser does not support the video tag.
            </video>
          )}
          {fileType.startsWith('audio/') && (
            <audio controls className="preview-audio">
              <source src={filePreview} type={fileType} />
              Your browser does not support the audio element.
            </audio>
          )}
        </Box>
      )}

      {/* Pulsanti di azione */}
      <Box className="buttons-container">
        <Button
          onClick={handlePost}
          variant="contained"
          color="primary"
          disabled={isUploading}
          startIcon={isUploading ? <CircularProgress size={20} /> : null}
        >
          {isUploading ? 'Posting...' : 'POST'}
        </Button>

        <Button
          onClick={clearForm}
          variant="outlined"
          color="secondary"
          startIcon={<DeleteIcon />}
        >
          Clear
        </Button>
      </Box>
    </Box>
  );
}

export default PostForm;
